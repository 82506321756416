import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import PageHeading from '../components/PageHeading'
import '../assets/sass/style.scss'

const Contact = ({ props }) => {
    
    return (
        <Layout>
            <SEO title="Contact Laura" description="Whether you’d like to join a class, get a quote or some exercise advice, Laura would love to hear from you." />
            
            <PageHeading text="Contact" className="no-bg">
                <p>Whether you’d like to join a class, get a quote for a feature, or receive some straightforward exercise advice, Laura would love to hear from you.</p>
                <p><a href="mailto:laura@laurawilliamsonline.co.uk">laura@laurawilliamsonline.co.uk</a></p>
            </PageHeading>

        </Layout>
    )
}

export default Contact